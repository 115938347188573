var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};

// src/react/switch/index.ts
import * as React from "react";
import { createComponent } from "@lit-labs/react";

// src/components/switch/switch.component.ts
import { property as property2, query, state } from "lit/decorators.js";
import { html, unsafeCSS } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";

// src/internal/nine-element.ts
import { LitElement } from "lit";
import { property } from "lit/decorators.js";
var NineElement = class extends LitElement {
  constructor() {
    super();
    this.dir = "ltr";
    this.lang = "en";
    Object.entries(this.constructor.dependencies).forEach(([name, component2]) => {
      this.constructor.define(name, component2);
    });
  }
  emit(name, options) {
    const event = new CustomEvent(name, __spreadValues({
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: {}
    }, options));
    this.dispatchEvent(event);
    return event;
  }
  get isReactElement() {
    return Object.keys(this).some((key) => key.startsWith("__react"));
  }
  /* eslint-enable */
  static define(name, elementConstructor = this, options = {}) {
    const currentlyRegisteredConstructor = customElements.get(name);
    if (!currentlyRegisteredConstructor) {
      customElements.define(name, class extends elementConstructor {
      }, options);
      return;
    }
    let newVersion = " (unknown version)";
    let existingVersion = newVersion;
    if ("version" in elementConstructor && elementConstructor.version) {
      newVersion = " v" + elementConstructor.version;
    }
    if ("version" in currentlyRegisteredConstructor && currentlyRegisteredConstructor.version) {
      existingVersion = " v" + currentlyRegisteredConstructor.version;
    }
    if (newVersion && existingVersion && newVersion === existingVersion) {
      return;
    }
    console.warn(
      `Attempted to register <${name}>${newVersion}, but <${name}>${existingVersion} has already been registered.`
    );
  }
};
/* eslint-disable */
// @ts-ignore
NineElement.version = true ? "2.0.34-localdev-1725354404084" : "dev";
NineElement.dependencies = {};
__decorateClass([
  property()
], NineElement.prototype, "dir", 2);
__decorateClass([
  property()
], NineElement.prototype, "lang", 2);

// src/internal/form.ts
var formCollections = /* @__PURE__ */ new WeakMap();
var reportValidityOverloads = /* @__PURE__ */ new WeakMap();
var userInteractedControls = /* @__PURE__ */ new WeakSet();
var interactions = /* @__PURE__ */ new WeakMap();
var FormControlController = class {
  constructor(host, options) {
    this.handleFormData = (event) => {
      const disabled = this.options.disabled(this.host);
      const name = this.options.name(this.host);
      const value = this.options.value(this.host);
      const isButton = this.host.tagName.toLowerCase() === "sl-button";
      if (!disabled && !isButton && typeof name === "string" && name.length > 0 && typeof value !== "undefined") {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            event.formData.append(name, val.toString());
          });
        } else {
          event.formData.append(name, value.toString());
        }
      }
    };
    this.handleFormSubmit = (event) => {
      var _a;
      const disabled = this.options.disabled(this.host);
      const reportValidity = this.options.reportValidity;
      if (this.form && !this.form.noValidate) {
        (_a = formCollections.get(this.form)) == null ? void 0 : _a.forEach((control) => {
          this.setUserInteracted(control, true);
        });
      }
      if (this.form && !this.form.noValidate && !disabled && !reportValidity(this.host)) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    };
    this.handleFormReset = () => {
      this.options.setValue(this.host, this.options.defaultValue(this.host));
      this.setUserInteracted(this.host, false);
      interactions.set(this.host, []);
    };
    this.handleInteraction = (event) => {
      const emittedEvents = interactions.get(this.host);
      if (!emittedEvents.includes(event.type)) {
        emittedEvents.push(event.type);
      }
      if (emittedEvents.length === this.options.assumeInteractionOn.length) {
        this.setUserInteracted(this.host, true);
      }
    };
    this.reportFormValidity = () => {
      if (this.form && !this.form.noValidate) {
        const elements = this.form.querySelectorAll("*");
        for (const element of elements) {
          if (typeof element.reportValidity === "function") {
            if (!element.reportValidity()) {
              return false;
            }
          }
        }
      }
      return true;
    };
    (this.host = host).addController(this);
    this.options = __spreadValues({
      form: (input) => {
        if (input.hasAttribute("form") && input.getAttribute("form") !== "") {
          const root = input.getRootNode();
          const formId = input.getAttribute("form");
          if (formId) {
            return root.getElementById(formId);
          }
        }
        return input.closest("form");
      },
      name: (input) => input.name,
      value: (input) => input.value,
      defaultValue: (input) => input.defaultValue,
      disabled: (input) => {
        var _a;
        return (_a = input.disabled) != null ? _a : false;
      },
      reportValidity: (input) => typeof input.reportValidity === "function" ? input.reportValidity() : true,
      setValue: (input, value) => input.value = value,
      assumeInteractionOn: ["sl-input"]
    }, options);
  }
  hostConnected() {
    const form = this.options.form(this.host);
    if (form) {
      this.attachForm(form);
    }
    interactions.set(this.host, []);
    this.options.assumeInteractionOn.forEach((event) => {
      this.host.addEventListener(event, this.handleInteraction);
    });
  }
  hostDisconnected() {
    this.detachForm();
    interactions.delete(this.host);
    this.options.assumeInteractionOn.forEach((event) => {
      this.host.removeEventListener(event, this.handleInteraction);
    });
  }
  hostUpdated() {
    const form = this.options.form(this.host);
    if (!form) {
      this.detachForm();
    }
    if (form && this.form !== form) {
      this.detachForm();
      this.attachForm(form);
    }
    if (this.host.hasUpdated) {
      this.setValidity(this.host.validity.valid);
    }
  }
  /** Returns the associated `<form>` element, if one exists. */
  getForm() {
    var _a;
    return (_a = this.form) != null ? _a : null;
  }
  /** Resets the form, restoring all the control to their default value */
  reset(submitter) {
    this.doAction("reset", submitter);
  }
  /** Submits the form, triggering validation and form data injection. */
  submit(submitter) {
    this.doAction("submit", submitter);
  }
  /**
   * Synchronously sets the form control's validity. Call this when you know the future validity but need to update
   * the host element immediately, i.e. before Lit updates the component in the next update.
   */
  setValidity(isValid) {
    const host = this.host;
    const hasInteracted = Boolean(userInteractedControls.has(host));
    const required = Boolean(host.required);
    host.toggleAttribute("data-required", required);
    host.toggleAttribute("data-optional", !required);
    host.toggleAttribute("data-invalid", !isValid);
    host.toggleAttribute("data-valid", isValid);
    host.toggleAttribute("data-user-invalid", !isValid && hasInteracted);
    host.toggleAttribute("data-user-valid", isValid && hasInteracted);
  }
  /**
   * Updates the form control's validity based on the current value of `host.validity.valid`. Call this when anything
   * that affects constraint validation changes so the component receives the correct validity states.
   */
  updateValidity() {
    const host = this.host;
    this.setValidity(host.validity.valid);
  }
  /**
   * Dispatches a non-bubbling, cancelable custom event of type `sl-invalid`.
   * If the `sl-invalid` event will be cancelled then the original `invalid`
   * event (which may have been passed as argument) will also be cancelled.
   * If no original `invalid` event has been passed then the `sl-invalid`
   * event will be cancelled before being dispatched.
   */
  emitInvalidEvent(originalInvalidEvent) {
    const slInvalidEvent = new CustomEvent("sl-invalid", {
      bubbles: false,
      composed: false,
      cancelable: true,
      detail: {}
    });
    if (!originalInvalidEvent) {
      slInvalidEvent.preventDefault();
    }
    if (!this.host.dispatchEvent(slInvalidEvent)) {
      originalInvalidEvent == null ? void 0 : originalInvalidEvent.preventDefault();
    }
  }
  attachForm(form) {
    if (form) {
      this.form = form;
      if (formCollections.has(this.form)) {
        formCollections.get(this.form).add(this.host);
      } else {
        formCollections.set(this.form, /* @__PURE__ */ new Set([this.host]));
      }
      this.form.addEventListener("formdata", this.handleFormData);
      this.form.addEventListener("submit", this.handleFormSubmit);
      this.form.addEventListener("reset", this.handleFormReset);
      if (!reportValidityOverloads.has(this.form)) {
        reportValidityOverloads.set(this.form, this.form.reportValidity);
        this.form.reportValidity = () => this.reportFormValidity();
      }
    } else {
      this.form = void 0;
    }
  }
  detachForm() {
    var _a;
    if (this.form) {
      (_a = formCollections.get(this.form)) == null ? void 0 : _a.delete(this.host);
      this.form.removeEventListener("formdata", this.handleFormData);
      this.form.removeEventListener("submit", this.handleFormSubmit);
      this.form.removeEventListener("reset", this.handleFormReset);
      if (reportValidityOverloads.has(this.form)) {
        this.form.reportValidity = reportValidityOverloads.get(this.form);
        reportValidityOverloads.delete(this.form);
      }
    }
    this.form = void 0;
  }
  setUserInteracted(el, hasInteracted) {
    if (hasInteracted) {
      userInteractedControls.add(el);
    } else {
      userInteractedControls.delete(el);
    }
    el.requestUpdate();
  }
  doAction(type, submitter) {
    if (this.form) {
      const button = document.createElement("button");
      button.type = type;
      button.style.position = "absolute";
      button.style.width = "0";
      button.style.height = "0";
      button.style.clipPath = "inset(50%)";
      button.style.overflow = "hidden";
      button.style.whiteSpace = "nowrap";
      if (submitter) {
        button.name = submitter.name;
        button.value = submitter.value;
        ["formaction", "formenctype", "formmethod", "formnovalidate", "formtarget"].forEach((attr) => {
          if (submitter.hasAttribute(attr)) {
            button.setAttribute(attr, submitter.getAttribute(attr));
          }
        });
      }
      this.form.append(button);
      button.click();
      button.remove();
    }
  }
};
var validValidityState = Object.freeze({
  badInput: false,
  customError: false,
  patternMismatch: false,
  rangeOverflow: false,
  rangeUnderflow: false,
  stepMismatch: false,
  tooLong: false,
  tooShort: false,
  typeMismatch: false,
  valid: true,
  valueMissing: false
});
var valueMissingValidityState = Object.freeze(__spreadProps(__spreadValues({}, validValidityState), {
  valid: false,
  valueMissing: true
}));
var customErrorValidityState = Object.freeze(__spreadProps(__spreadValues({}, validValidityState), {
  valid: false,
  customError: true
}));

// src/internal/default-value.ts
import { defaultConverter } from "lit";
var defaultValue = (propertyName = "value") => (proto, key) => {
  const ctor = proto.constructor;
  const attributeChangedCallback = ctor.prototype.attributeChangedCallback;
  ctor.prototype.attributeChangedCallback = function(name, old, value) {
    var _a;
    const options = ctor.getPropertyOptions(propertyName);
    const attributeName = typeof options.attribute === "string" ? options.attribute : propertyName;
    if (name === attributeName) {
      const converter = options.converter || defaultConverter;
      const fromAttribute = typeof converter === "function" ? converter : (_a = converter == null ? void 0 : converter.fromAttribute) != null ? _a : defaultConverter.fromAttribute;
      const newValue = fromAttribute(value, options.type);
      if (this[propertyName] !== newValue) {
        this[key] = newValue;
      }
    }
    attributeChangedCallback.call(this, name, old, value);
  };
};

// src/components/switch/switch.scss?inline
import { css } from "lit-element/lit-element.js";
var switch_default = css`:host {
  box-sizing: border-box;
  display: inline-block;
  --height: 1.3rem;
  --thumb-size: calc(var(--height) + 4px);
  --width: calc(var(--height) * 2);
  --main-color: #fff;
  --hl-color: #746E74;
  --line-color: #9e9e9e;
  --border-color: #9e9e9e;
  --focus-ring: 0 0 0 2px #2196f3;
  --focus-ring-offset: 2px;
  --input-required-content: "*";
  --input-required-content-offset: 0.25em;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
:host[hidden] {
  display: none !important;
}

.switch {
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.02em;
  margin: 0.8em 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: inherit;
  color: var(--label-color, #000);
  vertical-align: middle;
  cursor: pointer;
}

.control {
  flex: 0 0 auto;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--width);
  height: var(--height);
  background-color: var(--hl-color);
  border: solid var(--border-color) var(--hl-color);
  border-radius: var(--height);
  transition: 0.2s border-color, 0.3s background;
}

.control .thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  background-color: var(--main-color);
  border-radius: 50%;
  border: solid var(--border-color) var(--hl-color);
  translate: calc((var(--width) - var(--height)) / -2);
  transition: 0.2s translate ease, 0.2s background-color, 0.2s border-color, 0.2s box-shadow;
  position: relative;
}
.control .thumb::after, .control .thumb::before {
  position: absolute;
  content: "";
  inset: 0;
  border-radius: 50%;
  background: white;
}
.control .thumb::before {
  box-shadow: -0.2rem 0.2rem 0.2rem rgba(51, 51, 51, 0.25);
  transform: scale(1);
  transition: 0.1s background-color, 0.1s transform, 0.1s box-shadow;
}

.input {
  position: absolute;
  opacity: 0;
  padding: 0;
  margin: 0;
  pointer-events: none;
}

/* Focus */
.switch:not(.is-checked):not(.is-disabled).has-focus .control {
  background-color: var(--hl-color);
  border-color: var(--hl-color);
}
.switch:not(.is-checked):not(.is-disabled).has-focus .control .thumb {
  background-color: var(--main-color);
  border-color: var(--hl-color);
}
.switch:not(.is-checked):not(.is-disabled).has-focus .control .thumb::before {
  background-color: rgba(0, 0, 0, 0.1333333333);
  box-shadow: -0.1rem 0.1rem 0.1rem rgba(51, 51, 51, 0.2);
  transform: scale(1.8);
}

.switch:not(.is-checked):not(.is-disabled) .input:focus ~ .control .thumb {
  background-color: var(--main-color);
  border-color: var(--line-color);
  outline: var(--focus-ring);
  outline-offset: var(--focus-ring-offset);
}

/* Checked */
.is-checked .control {
  background-color: var(--line-color);
  background-image: var(--gradient-sunrise);
  border-color: var(--line-color);
}

.is-checked .control .thumb {
  background-color: var(--main-color);
  border-color: var(--line-color);
  translate: calc((var(--width) - var(--height)) / 2);
}

/* Checked + focus */
.switch.is-checked:not(.is-disabled).has-focus .control {
  background-color: var(--line-color);
  border-color: var(--line-color);
}
.switch.is-checked:not(.is-disabled).has-focus .control .thumb::before {
  background-color: rgba(171, 212, 255, 0.4);
  box-shadow: -0.1rem 0.1rem 0.1rem rgba(51, 51, 51, 0.2);
  transform: scale(1.8);
}

.switch.is-checked:not(.is-disabled) .input:focus ~ .control .thumb {
  background-color: var(--main-color);
  border-color: var(--line-color);
  outline: var(--focus-ring);
  outline-offset: var(--focus-ring-offset);
}

/* Disabled */
.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.switch__label {
  display: inline-block;
  line-height: var(--height);
  margin-inline-start: 0.5em;
  user-select: none;
}

:host([required]) .switch__label::after {
  content: var(--input-required-content);
  margin-inline-start: var(--input-required-content-offset);
}

@media (forced-colors: active) {
  .switch.is-checked:not(.is-disabled) .control:hover .thumb,
  .is-checked .control .thumb {
    background-color: ButtonText;
  }
}
@media (hover: hover) {
  .switch:not(.is-checked):not(.is-disabled) .control:hover {
    background-color: var(--hl-color);
    border-color: var(--hl-color);
  }
  .switch:not(.is-checked):not(.is-disabled) .control:hover .thumb {
    background-color: var(--main-color);
    border-color: var(--hl-color);
  }
  .switch:not(.is-checked):not(.is-disabled) .control:hover .thumb::before {
    background-color: rgba(0, 0, 0, 0.1333333333);
    box-shadow: -0.1rem 0.1rem 0.1rem rgba(51, 51, 51, 0.2);
    transform: scale(1.8);
  }
  .switch.is-checked:not(.is-disabled) .control:hover {
    background-color: var(--line-color);
    border-color: var(--line-color);
  }
  .switch.is-checked:not(.is-disabled) .control:hover .thumb::before {
    background-color: rgba(171, 212, 255, 0.4);
    box-shadow: -0.1rem 0.1rem 0.1rem rgba(51, 51, 51, 0.2);
    transform: scale(1.8);
  }
}`;

// src/internal/watch.ts
function watch(propertyName, options) {
  const resolvedOptions = __spreadValues({
    waitUntilFirstUpdate: false
  }, options);
  return (proto, decoratedFnName) => {
    const { update } = proto;
    const watchedProperties = Array.isArray(propertyName) ? propertyName : [propertyName];
    proto.update = function(changedProps) {
      watchedProperties.forEach((property3) => {
        const key = property3;
        if (changedProps.has(key)) {
          const oldValue = changedProps.get(key);
          const newValue = this[key];
          if (oldValue !== newValue) {
            if (!resolvedOptions.waitUntilFirstUpdate || this.hasUpdated) {
              this[decoratedFnName](oldValue, newValue);
            }
          }
        }
      });
      update.call(this, changedProps);
    };
  };
}

// src/components/switch/switch.component.ts
var NineSwitch = class extends NineElement {
  constructor() {
    super(...arguments);
    this.formControlController = new FormControlController(this, {
      value: (control) => control.checked ? control.value || "on" : void 0,
      defaultValue: (control) => control.defaultChecked,
      setValue: (control, value) => control.checked = Boolean(value)
    });
    this.input = null;
    this.title = "";
    this.name = "";
    this.disabled = false;
    this.checkedInternal = false;
    this.form = "";
    this.required = false;
    this.defaultChecked = false;
    this.hasFocus = false;
    this.handleCheckedInternalChange = (_was, is) => {
      if (is !== this.checked) {
        this.emit("nine-change");
      }
    };
    this.handleCheckedChange = (_was, is) => {
      this.checkedInternal = is;
    };
    this.handleFocusChange = (was) => {
      if (!was) {
        this.emit("nine-focus");
      } else {
        this.emit("nine-blur");
      }
    };
  }
  get validity() {
    return this.input.validity;
  }
  get validationMessage() {
    return this.input.validationMessage;
  }
  /** Simulates a click on the switch. */
  click() {
    if (!this.input) return;
    this.input.click();
  }
  /** Sets focus on the switch. */
  focus(options) {
    if (!this.input) return;
    this.input.focus(options);
  }
  /** Removes focus from the switch. */
  blur() {
    this.input.blur();
  }
  /** Checks for validity but does not show a validation message. Returns `true` when valid and `false` when invalid. */
  checkValidity() {
    if (!this.input) return false;
    return this.input.checkValidity();
  }
  /** Gets the associated form, if one exists. */
  getForm() {
    return this.formControlController.getForm();
  }
  /** Checks for validity and shows the browser's validation message if the control is invalid. */
  reportValidity() {
    if (!this.input) return false;
    return this.input.reportValidity();
  }
  /** Sets a custom validation message. Pass an empty string to restore validity. */
  setCustomValidity(message) {
    if (!this.input) return;
    this.input.setCustomValidity(message);
    this.formControlController.updateValidity();
  }
  firstUpdated() {
    this.formControlController.updateValidity();
    if (typeof this.checked === "boolean") {
      this.checkedInternal = this.checked;
    }
  }
  setChecked(set) {
    if (this.isReactElement && typeof this.checked === "boolean") {
      this.emit("nine-change");
      return;
    }
    this.checkedInternal = set;
  }
  handleBlur() {
    this.hasFocus = false;
  }
  handleInput() {
    this.emit("nine-input");
  }
  handleInvalid(event) {
    this.formControlController.setValidity(false);
    this.formControlController.emitInvalidEvent(event);
  }
  handleClick() {
    this.setChecked(!this.checkedInternal);
  }
  handleFocus() {
    this.hasFocus = true;
  }
  handleKeyDown(event) {
    const currentChecked = this.checkedInternal;
    if (event.key === "ArrowLeft") {
      event.preventDefault();
      this.setChecked(false);
    }
    if (event.key === "ArrowRight") {
      event.preventDefault();
      this.setChecked(true);
    }
    const changed = currentChecked !== this.checkedInternal;
    if (changed) {
      this.handleInput();
    }
  }
  render() {
    return html`
      <label
        part="base"
        class=${classMap({
      switch: true,
      "is-checked": this.checkedInternal,
      "is-disabled": this.disabled,
      "has-focus": this.hasFocus
    })}
      >
        <input
          class="input"
          type="checkbox"
          title=${this.title}
          name=${this.name}
          value=${ifDefined(this.value)}
          .checked=${live(this.checkedInternal)}
          .disabled=${this.disabled}
          .required=${this.required}
          role="switch"
          aria-checked=${this.checkedInternal ? "true" : "false"}
          @click=${this.handleClick}
          @input=${this.handleInput}
          @invalid=${this.handleInvalid}
          @blur=${this.handleBlur}
          @focus=${this.handleFocus}
          @keydown=${this.handleKeyDown}
        />
        <span part="control" class="control"><span part="thumb" class="thumb"></span></span>
        <slot part="label" class="switch__label"></slot>
      </label>
    `;
  }
};
NineSwitch.styles = unsafeCSS(switch_default);
__decorateClass([
  query('input[type="checkbox"]')
], NineSwitch.prototype, "input", 2);
__decorateClass([
  property2()
], NineSwitch.prototype, "title", 2);
__decorateClass([
  property2()
], NineSwitch.prototype, "name", 2);
__decorateClass([
  property2()
], NineSwitch.prototype, "value", 2);
__decorateClass([
  property2({ type: Boolean, reflect: true })
], NineSwitch.prototype, "disabled", 2);
__decorateClass([
  property2({ type: Boolean, reflect: true })
], NineSwitch.prototype, "checked", 2);
__decorateClass([
  property2({ type: Boolean, reflect: false })
], NineSwitch.prototype, "checkedInternal", 2);
__decorateClass([
  property2({ reflect: true })
], NineSwitch.prototype, "form", 2);
__decorateClass([
  property2({ type: Boolean, reflect: true })
], NineSwitch.prototype, "required", 2);
__decorateClass([
  defaultValue("checked")
], NineSwitch.prototype, "defaultChecked", 2);
__decorateClass([
  state()
], NineSwitch.prototype, "hasFocus", 2);
__decorateClass([
  watch("checkedInternal", { waitUntilFirstUpdate: true })
], NineSwitch.prototype, "handleCheckedInternalChange", 2);
__decorateClass([
  watch("checked", { waitUntilFirstUpdate: true })
], NineSwitch.prototype, "handleCheckedChange", 2);
__decorateClass([
  watch("hasFocus", { waitUntilFirstUpdate: true })
], NineSwitch.prototype, "handleFocusChange", 2);

// src/components/switch/switch.ts
var switch_default2 = NineSwitch;
NineSwitch.define("nine-switch");

// src/react/switch/index.ts
var tagName = "nine-switch";
var component = createComponent({
  tagName,
  elementClass: switch_default2,
  react: React,
  events: {
    onNineBlur: "nine-blur",
    onNineChange: "nine-change",
    onNineInput: "nine-input",
    onNineFocus: "nine-focus",
    onNineInvalid: "nine-invalid"
  },
  displayName: "NineSwitch"
});
var NineSwitch2 = class extends React.Component {
  constructor(...args) {
    super(...args);
    switch_default2.define(tagName);
  }
  render() {
    const _a = this.props, { children } = _a, props = __objRest(_a, ["children"]);
    return React.createElement(component, props, children);
  }
};
var switch_default3 = NineSwitch2;
export {
  switch_default3 as default
};
